import 'styles/reset.css';
import 'styles/global.css';

import React, { ReactElement, ReactNode } from 'react';
import App from 'next/app';
import { AppContext, AppProps } from 'next/app';
import { useRouter } from 'next/router';
import { groq } from 'next-sanity';
import { sanityClient, usePreviewSubscription } from 'utils/sanity';
import { useApollo } from 'utils/apollo-client';
import { ApolloProvider } from '@apollo/client/react';
import { UserProvider } from '@auth0/nextjs-auth0';
import { NextPage } from 'next';
import NextNProgress from 'nextjs-progressbar';

import { useGtm } from 'utils/gtm/hooks';
import { getDefaultLayout } from 'components/layouts/Default';
import { GlobalSettings } from 'utils/types';
import { useSetFeatureFlags } from 'utils/featureFlags';
import theme from 'utils/theme';

interface MyAppProps extends AppProps {
  props: {
    settings: GlobalSettings;
  };
  Component: NextPage & {
    getLayout?: (page: ReactElement, settings: GlobalSettings) => ReactNode;
  };
}

const settingsQuery = groq`
  *[_type == "siteSettings" && _id == "siteSettings"][0] {
    ...,
    pressBanner {
      images[] {
        ...,
        asset->
      }
    },
  }
`;

function MyApp({ Component, pageProps, props }: MyAppProps) {
  const router = useRouter();
  const apolloClient = useApollo(pageProps);

  const { data: settings } = usePreviewSubscription(settingsQuery, {
    initialData: props.settings,
    enabled: !!router.query.cmsPreview,
  });

  useGtm();

  useSetFeatureFlags();

  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout || getDefaultLayout;

  return (
    <UserProvider>
      <ApolloProvider client={apolloClient}>
        <NextNProgress
          color={theme.colors.pink}
          options={{ showSpinner: false }}
        />
        {getLayout(<Component {...pageProps} />, settings)}
      </ApolloProvider>
    </UserProvider>
  );
}

// Only uncomment this method if you have blocking data requirements for
// every single page in your application. This disables the ability to
// perform automatic static optimization, causing every page in your app to
// be server-side rendered.
//
MyApp.getInitialProps = async (appContext: AppContext) => {
  // calls page's `getInitialProps` and fills `appProps.pageProps`
  const appProps = await App.getInitialProps(appContext);

  const settings = await sanityClient.fetch(settingsQuery);

  return {
    ...appProps,
    props: { settings },
  };
};

export default MyApp;
