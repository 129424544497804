import {
  CustomFieldsFragment,
  Maybe,
  LineItemDataFragment,
} from 'generated/api/graphql';
import {
  NormalizedCustomFields,
  NormalizedAttributes,
} from './commercetools/types';
import { IResponsivePaddingObj } from './types';

export const chunkData = (array: any[], size: number): any =>
  array.reduce((chunks: any[], item: any, i: number) => {
    if (i % size === 0) {
      chunks.push([item]);
    } else {
      chunks[chunks.length - 1].push(item);
    }
    return chunks;
  }, []);

/**
 * Converts the custom fields array to a key value pair object
 * @param {CustomFieldsFragment | undefined | null} props
 * @returns {NormalizedCustomFields}
 */
export function normalizeCustomFields(
  custom: Maybe<CustomFieldsFragment>,
): NormalizedCustomFields {
  const normalizedCustomFields: NormalizedCustomFields = {};

  if (!custom || !custom.customFieldsRaw || !custom.customFieldsRaw?.length) {
    return normalizedCustomFields;
  }

  custom.customFieldsRaw.forEach(({ name, value }) => {
    normalizedCustomFields[name] = value;
  });

  return normalizedCustomFields;
}

/**
 * Converts the custom fields array to a key value pair object
 * @param {CustomFieldsFragment | undefined | null} props
 * @returns {NormalizedAttributes}
 */
export function normalizeAttributesRaw(
  variant: Maybe<LineItemDataFragment['variant']>,
): NormalizedAttributes {
  const normalizedAttributes: NormalizedAttributes = {};

  if (
    !variant ||
    !variant.attributesRaw ||
    variant.attributesRaw.length === 0
  ) {
    return normalizedAttributes;
  }

  variant.attributesRaw.forEach(({ name, value }) => {
    normalizedAttributes[name] = value;
  });

  return normalizedAttributes;
}

/**
 * Used to throw an error if this function is invoked server-side
 */
export function throwServerSideError() {
  if (typeof window === 'undefined') {
    throw Error('This function can only be used client-side');
  }
}

export function throwClientSideError() {
  if (typeof window !== 'undefined') {
    throw Error('This function can only be used server-side');
  }
}

/**
 * Converts a centAmount to dollarAmount
 * @param {number | undefined | null} price
 * @returns {number | null} dollarAmount
 */
export function convertCentAmountToDollars(
  price: number | undefined | null,
): number | null {
  return price ? price / 100 : null;
}

/**
 * Ensures that any URLs passed to it are relative to the website
 * @param {string} url
 * @returns {string} relativeUrl
 */
export function relativeUrl(url: string): string {
  if (url.includes(':')) {
    return url;
  }

  if (!url.startsWith('/')) {
    return `/${url}`;
  }

  return url;
}

/**
 * Returns item/items based on the count
 * @param {number} count
 * @param {boolean} capitalise
 * @returns {string} item/items
 */
export function getItemItemsString(count: number, capitalise = true): string {
  if (count === 1) {
    return capitalise ? 'Item' : 'item';
  }
  return capitalise ? 'Items' : 'items';
}

export const toTitleCase = (phrase: string) =>
  (phrase || '')
    .toString()
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

/**
 * Truncate the given string if it's longer then `maxLength`.
 */
export const truncate = (text: string, maxLength: number) =>
  text.length > maxLength ? `${text.substr(0, maxLength - 3)}...` : text;

/**
 * Scrolls the user to the top of the page. It scrolls to 5px from the top so it
 * doesn't trigger iOS's top navigation bar on scroll
 */
export function scrollToTop() {
  if (typeof window !== 'undefined') {
    window.scrollTo(0, 5);
  }
}

/**
 * Scrolls an element into view
 * @param {string} id - ID of element to scroll into view
 * @param {boolean} smooth - true for a smooth scroll beahviour
 */
export function scrollIntoView(id: string, smooth = true) {
  document
    .getElementById(id)
    ?.scrollIntoView({ behavior: smooth ? 'smooth' : 'auto' });
}

/**
 * Returns true if app running in browser
 */
export function isClientSide() {
  return typeof window !== 'undefined' && document;
}

/**
 * Returns media queries for responsive containers via padding bottom percentages
 */
export const getResponsivePaddingStyles = (
  breakpointConfig: IResponsivePaddingObj[],
) =>
  breakpointConfig.map(
    ({ breakpoint, paddingBottomPercentage }) =>
      `@media(min-width: ${breakpoint}px) { padding-bottom: ${paddingBottomPercentage}; } `,
  );
