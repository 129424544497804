import LoadingText from 'components/global/LoadingText';
import React from 'react';
import { ButtonEl, ChildrenWrapper } from './styles';
import { ButtonProps } from './types';

export default function Button({
  id,
  as,
  name,
  loading,
  onClick,
  children,
  disabled,
  selected,
  colorScheme,
  width = 'auto',
  type = 'button',
  variant = 'primary',
  loadingText = 'Loading',
  'data-testid': dataTestId,
}: ButtonProps) {
  const defaultColorScheme = variant === 'primary' ? 'pineGreen' : 'black';

  return (
    <ButtonEl
      as={as}
      id={id}
      name={name}
      width={width}
      onClick={onClick}
      variant={variant}
      loading={loading}
      selected={selected}
      type={as ? null : type}
      data-testid={dataTestId}
      aria-selected={selected}
      disabled={loading || disabled}
      aria-disabled={loading || disabled}
      colorScheme={colorScheme || defaultColorScheme}
    >
      <LoadingText text={loadingText} />
      <ChildrenWrapper>{children}</ChildrenWrapper>
    </ButtonEl>
  );
}
