import React, { FC } from 'react';

import { ILoadingTextProps } from './types';
import { LoadingTextContainer, AnimatedDot } from './styles';

const LoadingText: FC<ILoadingTextProps> = ({ text, alwaysShow = false }) => {
  return (
    <LoadingTextContainer alwaysShow={alwaysShow}>
      {text}
      <AnimatedDot delay={0.1} />
      <AnimatedDot delay={0.2} />
      <AnimatedDot delay={0.3} />
    </LoadingTextContainer>
  );
};

export default LoadingText;
